

.at-minh-auto {
  min-height: auto !important;
}

@media (max-width:991.98px) {
  .the--mobilefull {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-top: 0 !important;
    padding-bottom: 3.75rem;
  }
}

.at-minh-auto .the {
  margin-top: 13.0625rem;
}

@media (min-width:768px) {
  .at-minh-auto .the {
    margin-top: 11.25rem;
  }
}

@media (min-width:992px) {
  .at-minh-auto .the {
    margin-top: 13.875rem;
  }
}

@media (min-width:1400px) {
  .at-minh-auto .the {
    margin-top: 11.5rem;
  }
}


.the-section {
  position: relative;
  padding: 0 0 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: end;
}



@media (min-width:768px) {
  .the-section {
    min-height: 100vh;
  }
}

.the-section .img-banner {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #262b3f !important;
//   background: url(/themes/custom/sysltd/assets/images/services/the.webp) no-repeat center 0/cover;
  height: 18.75rem;
  width: 100%;
}

@media (min-width:992px) {
  .the-section .img-banner {
    height: 25rem;
  }
}

.the-section .img-banner-h100vh {
  height: 100%;
}

.the-section .img-banner-industries-telco {
//   background: url(/themes/custom/sysltd/assets/images/industry/banner-1.jpg) no-repeat center 0/cover;
}


.type-company-profile section.the-section,
.type-benefits section.the-section,
.type-trainees section.the-section,
.type-working-systems section.the-section,
.type-diversity-inclusion section.the-section {
  margin-bottom: 40px;
}

@media (min-width:768px) {

  .type-company-profile section.the-section,
  .type-benefits section.the-section,
  .type-trainees section.the-section,
  .type-working-systems section.the-section,
  .type-diversity-inclusion section.the-section {
    margin-bottom: 5rem;
  }
}

.image-text-section .sect-text a {
  color: #f26223;
}

@media (max-width:991.98px) {
  .type-aspire-karachi .the-section .the--mobilefull {
    min-height: 50vh !important;
  }
}



@media (min-width:992px) {

  .node-238 .the-section,
  .type-bfsi .the-section {
    min-height: 700px;
  }
}


.innerpage-hero-section .img-banner {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #262b3f;
  height: 13.125rem;
  width: 100%;
}

@media (min-width:992px) {
  .innerpage-hero-section .img-banner {
    height: 25rem;
  }
}

.innerpage-hero-section .img-banner-h100vh {
  height: 100vh;
}


@media (max-width:991.98px) {
  .bio-hero .img-banner {
    min-height: 350px;
  }
}



.job-openings-banner .the .the-display {
  margin-bottom: 0;
}

.job-openings-banner .the .the-display h6 {
  margin-bottom: 12px;
}



@media (max-width:767.98px) {
  .type-blogs .the-display {
    margin-top: 80px;
  }
}



@media (min-width:992px) {

  .node-195 .the-display h1,
  .type-governance .the-display h1 {
    font-size: 5rem;
    line-height: 1.25;
  }
}


.the-display {
  margin-bottom: 2rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  z-index: 5;
  position: relative;
}

.the-display h1 {
  font-weight: 300;
  font-size: 2.25rem;
  line-height: 2.75rem;
  background: linear-gradient(to bottom, #fff 0%, #fff 192px, #161616 0%, #161616 0%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width:1399.98px) {
  .the-display h1 {
    background: linear-gradient(to bottom, #fff 0%, #fff 154px, #161616 0%, #161616 0%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media (max-width:991.98px) {
  .the-display h1 {
    background: linear-gradient(to bottom, #fff 0%, #fff 96px, #161616 0%, #161616 0%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media (max-width:767.98px) {
  .the-display h1 {
    background: linear-gradient(to bottom, #fff 0%, #fff 71px, #161616 0%, #161616 0%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media (min-width:768px) {
  .the-display h1 {
    font-size: 3rem;
    line-height: 3.75rem;
  }
}

@media (min-width:992px) {
  .the-display h1 {
    font-size: 4.875rem;
    line-height: 6rem;
  }
}

@media (min-width:1400px) {
  .the-display h1 {
    font-size: 5.875rem;
    line-height: 7.5rem;
  }
}

.the-display h6 {
  text-transform: uppercase;
  font-size: 0.58125rem;
  font-weight: normal;
  letter-spacing: 1px;
}

@media (min-width:768px) {
  .the-display h6 {
    font-size: 0.75rem;
  }
}

.the-display .gradient-twolines {
  background: linear-gradient(to bottom, #fff 0%, #fff 79.7%, #161616 50%, #161616 100%);
  -webkit-background-clip: text;
}

.the-display .text-fillwhite {
  -webkit-text-fill-color: inherit;
}


@media (min-width:992px) {
  .job-openings-banner .the {
    margin-bottom: 3.5625rem;
    margin-top: 12rem;
  }
}

@media (max-width:767.98px) {
  .job-openings-banner .the {
    margin-bottom: 31px;
    margin-top: 109px;
  }
}

.job-openings-banner .the h1 {
  background: linear-gradient(to bottom, #fff 0%, #fff 78.5%, #161616 50%, #161616 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 3px;
}

@media (min-width:992px) {
  .job-openings-banner .the h1 {
    font-size: 94px;
    line-height: 113px;
  }
}

@media (max-width:767.98px) {
  .job-openings-banner .the h1 {
    margin-bottom: 0;
    -webkit-text-fill-color: inherit;
    font-size: 44px;
    line-height: 50px;
  }
}

@media (max-width:767.98px) {
  .job-openings-banner .the h1 br {
    display: none;
  }
}




.extra-space .the {
  margin-top: 11.25rem;
  margin-bottom: 3.5rem;
}

@media (min-width:992px) {
  .extra-space .the {
    margin-top: 18.75rem;
    margin-bottom: 6.5rem;
  }
}



@media (max-width:767.98px) {

  .node-223 .the--mobilefull,
  .type-blog .the--mobilefull {
    min-height: 400px;
  }
}


@media (min-width:992px) {
  .the {
    margin-bottom: 6.5rem;
  }
}

@media (min-width:1400px) {
  .the {
    margin-top: 11.5rem;
  }
}

@media (max-width:991.98px) {
  .the--mobilefull {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-top: 0 !important;
    padding-bottom: 3.75rem;
  }
}

.at-minh-auto .the {
  margin-top: 13.0625rem;
}

@media (min-width:768px) {
  .at-minh-auto .the {
    margin-top: 11.25rem;
  }
}

@media (min-width:992px) {
  .at-minh-auto .the {
    margin-top: 13.875rem;
  }
}

@media (min-width:1400px) {
  .at-minh-auto .the {
    margin-top: 11.5rem;
  }
}


@media (max-width:575.98px) {
  body.type-case-studies .the.the--mobilefull {
    margin-bottom: 3.25rem;
    padding-bottom: 0;
  }
}



h6,
h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width:1200px) {

  h1 {
    font-size: 2.5rem;
  }
}


h6 {
  font-size: 1rem;
}

h6 {
  line-height: 1.3 !important;
}