@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Alatsi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alatsi&display=swap');

body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Plus Jakarta Sans", serif !important;
  background-color: #ffffff;
}

h1 {
  font-size: clamp(50px, 3.35vw + 1rem, 200px);
  font-family: "Plus Jakarta Sans", serif !important;
}

p {
  font-family: "Plus Jakarta Sans", serif !important;
  font-size: 20px;
}

@media (max-width: 1300px) {
  p {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  p {
    font-size: 16px;
  }
}

$hfont: clamp(20px, 3.35vw + 1.8rem, 200px);
$hp: clamp(16px, 3.35vw + 0.125rem, 19px);
$htf: clamp(10px, 2.35vw + 7px, 24px);
$subheading: clamp(28px, 3.35vw + 1rem, 60px);
$smallheading: 20px;


$light: #ffffff;
$black: #000000;
$heading: #262B3F;
$subheading: #161616;
$paragraph: #161616;
$background: #26283F;
$headlight: #5f6368;
$divttext: #262B3F;
$divthead: #161616;
$boderlight: #dadce0;

$newscardhead: #26283F;
$bloghead: #212529;
$blogtext: #212529;

$head: #161616;

$footerlink: #202124;
$footerlolink: #616161;
$footerbg: #F8F9FA;

$headerli: #262626;

$linkcolour: #054c9c;

$notificationsbg: #F6F9FE;
$notificationcolour: #174ea6;
$notificationbordercolour: #1a73e8;
$notofivationhover: #f4f8fd;
$lightcolour: #5f6368;
// $linkcolour: #0064DA;

.bodertop {
  border-top: 1px solid;
}

.boderbottom {
  border-bottom: 1px solid;
}

.ct {
  object-fit: cover;
  object-position: center top;
}

.cc {
  object-fit: cover;
  object-position: center center;
}

.cl {
  object-fit: cover;
  object-position: center left;
}

.cr {
  object-fit: cover;
  object-position: center right;
}

.tl {
  object-fit: cover;
  object-position: top left;
}

.tr {
  object-fit: cover;
  object-position: top right;
}

.cb {
  object-fit: cover;
  object-position: center bottom;
}

.bl {
  object-fit: cover;
  object-position: bottom left;
}

.br {
  object-fit: cover;
  object-position: bottom right;
}

@keyframes button {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}


@keyframes button-light {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.text-dicoration-none {
  text-decoration: none;
}

@media (max-width: 767px) {
  .order1 {
    order: 1;
  }

  .order2 {
    order: 2;
  }

  $smallheading: 18px;
}

@media (max-width: 992px) {
  .lgorder1 {
    order: 1;
  }

  .lgorder2 {
    order: 2;
  }
}

// @media (min-width: 1319px) {
//   .container {
//     max-width: 1700px !important;
//     padding-left: 50px !important;
//     padding-right: 50px !important;
//   }
// }

@keyframes shine {
  0% {
    color: #ffffff;
  }

  10% {
    color: #66ccff;
  }

  20% {
    color: #ff99cc;
  }

  30% {
    color: #a8039271;
  }

  40% {
    color: #66ccff;
  }

  50% {
    color: #ff99cc;
  }

  60% {
    color: #31c02446;
  }

  70% {
    color: #66ccff;
  }

  80% {
    color: #ff99cc;
  }

  90% {
    color: #7738db9a;
  }

  100% {
    color: #ffffff;
  }
}

#button-dark-bg {
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #ffff;
  height: 51px;
  width: 199px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
}

#button-dark-bg:hover {
  background: rgb(0, 0, 0);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.829) 0%, rgba(1, 28, 64, 0.836) 100%);
  color: #ffff;
  animation: button 0.6s ease-in-out forwards;
}

#button-dark-bg:focus {
  background: rgb(0, 0, 0);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.829) 0%, rgba(1, 28, 64, 0.836) 100%);
  color: #ffff;
}

@media (max-width: 767px) {
  #button-dark-bg {
    height: 45px;
    width: 173px;
    font-size: 14px;
  }
}

#button-light-bg {
  background-color: #262B3F;
  border-radius: 5px;
  border: 1px solid #262B3F;
  height: 51px;
  width: 199px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
}

#button-light-bg:hover {
  background: rgb(0, 0, 0);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.829) 0%, rgba(1, 28, 64, 0.836) 100%);
  color: #ffff;
  animation: button 0.6s ease-in-out forwards;
}

#button-light-bg:focus {
  background: rgb(0, 0, 0);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.829) 0%, rgba(1, 28, 64, 0.836) 100%);
  color: #ffff;
}

@media (max-width: 767px) {
  #button-light-bg {
    height: 45px;
    width: 173px;
    font-size: 14px;
  }
}

.margin-bottom {
  margin-top: 40px;
}

@media (max-width: 767px) {
  .margin-bottom {
    margin-top: 30px;
  }
}

.rgb-high {
  --gradient-position: 50%;
  background: -webkit-linear-gradient(348deg, #000000 0%, #000000 28.66%, #FC66BD 32.66%, #FFC5E3 33%, #000000 37%, #000000 45%, #28E5FF 47.5%, #0064DA 48%, #000000 50.5%, #000000 64%, #FF31A1 69%, #000000 70%);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-size: auto;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  position: relative;
}

.rgb-low {
  --gradient-position: 30%;
  background: -webkit-linear-gradient(348deg, #000000 0%, #000000 20%, #FC66BD 24%, #FFC5E3 25%, #000000 30%, #000000 40%, #28E5FF 42.5%, #0064DA 43%, #000000 45.5%, #000000 60%, #FF31A1 65%, #000000 70%);
  background-position-x: 10%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-size: auto;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  position: relative;
}

.q-left {
  height: 70px;
  border-left: 3px solid $headlight;
}

.q-bottom {
  width: 100px;
  border-bottom: 3px solid $heading;
  margin-top: 40px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .q-bottom {
    margin-top: 60px;
  }
}

.success-toast {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 12px;
  font-weight: normal !important;
  padding-left: 10px !important;
  padding-top: 6px !important;
}


#two-pic-section {
  position: relative;
  padding: 0px 0 60px 0;
  margin-right: 15px !important;
  margin-top: 2px;
  margin-left: 15px !important;

  h1 {
    color: $head;
    margin-bottom: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: clamp(36px, 3.35vw + 1rem, 60px);
    font-weight: 400;
  }

  p {
    color: #262B3F;
    text-align: left;
  }
}

@media (max-width: 992px) {
  #two-pic-section {
    padding-bottom: 60px;

    h1 {
      margin-bottom: 20px;
      margin-top: 30px;
    }

    p {
      margin-bottom: 20px;
    }
  }
}

h4 {
  margin-bottom: 30px;
  font-size: 18px;
  margin-top: 0px;
  padding-top: 0px;
  color: $head;
}

@media (max-width: 992px) {
  h4 {
    margin-top: 0px !important;
    margin-bottom: 20px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  h4 {
    margin-top: 0px !important;
    margin-bottom: 20px;
    font-size: 13px;
  }
}

.vmc {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.vmimg {
  overflow: hidden !important;
  position: relative !important;
  box-sizing: border-box !important;
}

.mvimg {
  transition: 0.3s ease-in-out;
}

.mvimg:hover {
  transform: scale(1.2);
  transition: 0.3s ease-in-out;
}

@media (max-width: 767px) {
  .vmimg {
    overflow: hidden !important;
  }

  .div1 {
    order: 2;
    margin-top: 45px;
    padding: 0px;
  }

  .div2 {
    order: 1;
    margin-top: 45px;
  }

  .div3 {
    order: 3;
    margin-top: 45px;

  }

  .div4 {
    order: 4;
    margin-top: 45px;
    padding: 0px;
  }

  .div6 {
    order: 6;
    margin-top: 45px;
    padding: 0px;
  }

  .div5 {
    order: 5;
    margin-top: 45px;
  }

  .divx {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .order1 {
    order: 1;
  }

  .order2 {
    order: 2;
  }

  .order3 {
    order: 3;
  }

  .order4 {
    order: 4;
  }

  .cdiv1 {
    order: 2;
    margin-top: 45px;
  }
}

@media (max-width: 991px) {
  .lgdiv1 {
    order: 2;
    margin-top: 45px;
    padding: 0px !important;
  }

  .lgdiv2 {
    order: 1;
    margin-top: 45px;
    padding: 0px !important;
  }

  .lgdiv3 {
    order: 3;
    margin-top: 45px;
    padding: 0px !important;
  }

  .lgdiv4 {
    order: 4;
    margin-top: 45px;
    padding: 0px !important;
  }

  .lgdiv6 {
    order: 6;
    margin-top: 45px;
    padding: 0px !important;
  }

  .lgdiv5 {
    order: 5;
    margin-top: 45px;
    padding: 0px !important;
  }
}

@media (min-width: 992px) {
  .lgdiv2 {
    padding: 0px !important;
  }

  .lgdiv3 {
    padding: 0px !important;
  }
  .lgdiv5 {
    padding: 0px !important;
  }
}

.c-on {
  margin-top: auto;
  margin-bottom: auto;
}

#preloader-div{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  .preloader-text{
    color: #011c40;
    font-family: "Alatsi", sans-serif !important;
    font-size: 45px;
    letter-spacing: 8px;
  }
}
@media (max-width: 767px) {
  #preloader-div{
    .preloader-text{
      color: #011c40;
      font-family: "Alatsi", sans-serif !important;
      font-size: 27px;
      letter-spacing: 4px;
    }
  }
}

@media (min-width: 992px) {
  .lgdiv1,
  .lgdiv6 {
    padding-right: 20px;
  }
  .lgdiv4{
    padding-left: 20px;
  }
}
@media (min-width: 1200px) {
  .lgdiv1,
  .lgdiv6 {
    padding-right: 40px;
  }
  .lgdiv4{
    padding-left: 40px;
  }
}
@media (min-width: 1400px) {
  .lgdiv1,
  .lgdiv6 {
    padding-right: 60px;
  }
  .lgdiv4{
    padding-left: 60px;
  }
}
@media (min-width: 1401px) {
  .lgdiv1,
  .lgdiv6 {
    padding-right: 70px;
  }
  .lgdiv4{
    padding-left: 70px;
  }
}
.margen-top-104{
  margin-top: 104px;
}
.loading-light {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}
.loading-dark {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #000000;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
