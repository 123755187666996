@import "theme";

#header {
  height: 55px;
  padding: 0px 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: none !important;
  z-index: 1000;
  background-color: transparent;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ffffff46;
  background-color: transparent;
}
.headerwelcome{
  position: fixed !important;
}
#header.header-scrolled,
#header.header-fixed {
  transition: none !important;
  background-color: #ffffff;
}
#header #logo img {
  padding: 0;
  margin: 0;
  max-height: 45px;
  max-width: 45px;
  margin-right: 65px; 
  z-index: 1000;
  cursor: pointer;
}
#header #logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 150px;
}
.logow{
  width: 45px;
}
#logoimage{
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-bar {
  margin-top: 55px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px; 
  background-color: transparent; 
  z-index: 2;
}
.filled {
  height: 100%;
  background-color: gold;
  width: 0;
}
.hea-con{
  width: 100%;
  padding: 0px 15px;
}
.n-links li{
  list-style: none;
}
.n-links li a{
  font-size: 0.9rem;
  text-decoration: none;
  color: #ffff;    
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  border-bottom: 2px solid transparent;
  padding-bottom: 0px;  
  transition: none !important;
}
.n-links li a:hover{
  border-color: #ffff;
}
.n-navbar{
  width: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}
.n-navbar .n-links{
  display: flex;
  gap: clamp(1rem, 2vw + 0.5rem, 14rem);
  padding: 0px;
  margin: 0px;
  align-items: center;
  justify-content: center;
}
.n-navbar .toogle-btn{
  font-size: 35px;
  font-weight: lighter;
  cursor: pointer;
  display: none;
  color: #fff;
  z-index: 2000;
  margin-bottom: auto !important;
  margin-top: auto !important;
  line-height: 0.9;
  padding-left: 15px;  
  height: 60px;
  padding-top: 11px;
  width: 70px-15px;
}
.n-navbar .nav-btn{
  background: #262b3f;
  border-radius: 5px;
  border: 1px solid #0000009a;
  height: 44px;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  width: 190px;
  font-size: 13px;
  color: white;
  margin-left: 30px;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: auto;
  margin-bottom: auto;
  display: none ;
  text-align: center;
  padding-top: 12px;
  margin-right: 30px;
}
.n-navbar .nav-btn:hover{
  color: #ffffff;  
  background: linear-gradient(135deg, rgba(1, 28, 64, 0.836) 0%, rgba(0, 0, 0, 0.829) 100%);
  animation: scaleUpp 0.6s ease-in-out forwards;
}
#nav-btn{
  display: none;
}
.n-m-menu li{
  list-style: none;
  
}
.n-m-menu .ul-links-a{
  padding: 0px;
}
.n-m-menu li a{
  font-size: 14px;
  padding-left: 20px;
  text-decoration: none;
  color: $footerlink;
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.123);
  font-weight: normal;
}
.mobile-nav-link{
  font-size: 14px;
  margin-top: 7px;
  margin-left: 10px;
  transform: rotate(-30deg);
}
.mobile-nav-link:hover{
  transform: rotate(0deg);
}
.n-m-menu li:first-child a {
  border-top: 1.5px solid rgba(0, 0, 0, 0.123);
}
.n-m-menu li a:hover{
  color: rgba(0, 0, 0, 0.822);
  transition: 0s;
  .mobile-nav-link{
    transform: rotate(0deg);
  }
}
.n-m-menu .n-btn{
  background-color: #262b3f;
  border-radius: 5px;
  border: 1px solid #ffff;
  height: 51px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93%;
  height: 53px;
  margin: 0px auto;
  transition: 1s;
  color: white;
  font-size: 15px;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  position: sticky;
  text-align: center;
  top: 100%;
  margin-bottom: 10px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}
.n-m-menu .n-btn:hover{
  background: rgb(0,0,0);
  background: linear-gradient(135deg, rgb(0, 0, 0) 0%, rgb(1, 28, 64) 100%);
  color: #ffff;
  transition: all 1s linear;
}
.n-m-menu .n-btn:focus{
  background: rgb(0,0,0);
  background: linear-gradient(135deg, rgb(0, 0, 0) 0%, rgb(1, 28, 64) 100%);
  color: #ffff;
  transition: all 1s linear;
}
.n-m-menu{
  position: fixed;
  right: -100%;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffffd7;
  backdrop-filter: blur(7px);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 4;
  transition: .4s; 
}
.n-m-menu.open{
  right: 0;
  display: block;
}
.n-m-menu::-webkit-scrollbar {
  width: 0.1em;
}
.n-m-menu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.123);
}
.n-m-menu::-webkit-scrollbar {
  width: 8px; 
}
.n-m-menu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.123); 
  border-radius: 6px;
}
.n-m-menu::-webkit-scrollbar-track {
  background-color: #ffffff00; 
}
.n-m-menu::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.123); 
}
.act{
    color: #000;
    display: inline;
    padding: 8px 19px;
    background-color: #EFF0F0;
    border: 1px solid #e5e7e7;
    border-radius: 10px;
    margin-right: 9px;
    text-transform: uppercase;
    font-size: 14px;
  }
  .act:hover{
    color: black;
  }
  #broc{
    color: white;
  }
.fo{
    font-size: 30px !important;
  }
  .pro{
    background-color: transparent;
    height: 60px;
    width: 70px;
    overflow: hidden;
    cursor: pointer;  
    font-size: 26px;
    display: flex !important;
    align-items: center;
    justify-items: center;
    padding-left: 22px;
    transition: 0.2s ease-in-out;
    border: none;
    outline: none;
  }
  .pro:hover{
    animation: scaleUp 0.6s ease-in-out forwards;
  }
.lo-t{
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .expanded-content {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    animation: fade-in 0.3s ease-in-out;
    position: fixed;
    width: 200px;
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    top: 65px;
    right: 67px;
    display: flex;
    justify-content: right;
  }
  .contrast {
    color: #000 !important;
    background-color: #fff !important; 
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); 
    font-weight: bold;
    padding: 5px; 
  }
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @media(min-width: 955px){
    .expanded-content {    
      right: 13px;
  
    }
  }
  @media(min-width: 1600px){
    .expanded-content {
      right: calc(10px + ((100vw - 1600px)/2));
    }
  }
  .border-l{
    border-left: 1px solid #ffffff46;
    border-right: 1px solid #ffffff46;
    display: flex;
    align-items: center;
    height: 60px;
    justify-content: center;
    width: 70px;
    padding-top: 2px;
  }
  #mobilelogo{
    top: 0;
    left: 0;
    right: 0;
    height: 55px;  
    display: flex;
    align-items: center;
    padding-left: 2px;
  }
  #mobilelogo #mlogo img {
    padding: 0;
    margin: 0;
    max-height: 45px;
    margin-right: 65px; 
    z-index: 1000;
    cursor: pointer;
  }
  #mobilelogo #mlogo {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  
  #mobilelogoimage{
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  @media(max-width: 814px){
    .n-navbar .nav-btn{
      display: none !important;
    }
    .n-navbar .n-links{
      display: none !important;
    }
    .n-navbar .toogle-btn{
      display: block;
    }
    .portt{
      display: none !important;
    }
   
    .lo-t{
      display: flex;
      justify-content: left;
      align-items: center;
    }
  
  }
  @media(min-width: 814px){
    .n-m-menu{
      display: none !important;
    }
    .pp{
      display: none !important;
    }
    .n-navbar{
      justify-content: left;
    }
  }
  #footer {
    background: $footerbg;
    color: $footerlink;
    font-size: 14px;
  }
  #footer .footer-top {
    background: $footerbg;
    padding: 30px 0 0px 0;
  }
  .footer-links-margin{
    margin-bottom: 10px;
  }
  .fbbt{
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: first baseline baseline;
    background: $footerbg;
  }
  .fbbt .social-links-fs{
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: last baseline;
  }
  .fbbt .social-links-fs a{
    all: initial;
    font-size: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dotted transparent;
    margin-left: 14px;
    color: $footerlink;
  }
  .fbbt .social-links-fs a:hover{
    transform: rotate(10deg);
  }
  .fbbt p{  
    font-family: 'Plus Jakarta Sans', sans-serif !important; 
    font-size: 18px;
    font-weight: 400;
    margin: 0px;
    padding: 0px;
    color: #000;
    padding-top: 69px;
  }
  .btp{
    display: flex;
    align-items: center;
    justify-content: center;  
    gap: 4px;
    width: 175px;
    height: 42px;
    background-color: #F2F2F2;
    color: $footerlink;
    font-family: 'Plus Jakarta Sans', sans-serif !important;  
    font-size: 34px;
    cursor: pointer;
    border: 1px dotted black;
    line-height: 4px;
    text-decoration: none;
  }
  .btp:hover{
    background-color: #fff;
    border: 1px dotted $footerlink;
    box-shadow: 0 .125rem .25rem 0 rgba(0,0,0,.13),0 0 .0625rem 0 rgba(0,0,0,.11);
  }
  .btp p{
    color: $footerlink;
    font-family: 'Plus Jakarta Sans', sans-serif !important;  
    font-size: 16px;
    margin: 0px !important;
    cursor: pointer;
    padding-right: 11px !important;
    text-decoration: none;
  }
.fbt{
  height: 100px;
  display: grid;
  align-items: top;
  justify-content: right;
  background: $footerbg;
}
#footer .footer-links .footer-contact p{
  color: $footerlink;
  font-size: 16px;
  font-weight: 700;
  margin-top: 29px;
  margin-bottom: 14px;
}
#footer .footer-links .footer-contact .links{
  color: $footerlink;
  font-size: 15px;
  text-decoration: none;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;
  padding-bottom: 1px;
}
#footer .footer-links .footer-contact .links::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: currentColor; 
  transition: width 0.3s ease;
}
#footer .footer-links .footer-contact .links:hover::after {
  width: 100%;
}
#footer .footer-links .footer-contact br{
  margin-bottom: 12px;
}
#footer .footer-bottom{
  background-color: $footerbg;
}
#footer .footer-bottom p{
  color: $footerlink;
  font-size: 14px;
  background-color: $footerbg;
  margin: 0px;
}
#footer .footer-bottom .links{
  font-size: 14px;
  color: $footerlolink;
  text-decoration: none;
}
#footer .footer-bottom .links:hover{
  color: $footerlink;
}
.footer-links{
  padding-top: 20px;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem; 
  padding: 1rem 0; 
  height: 100px;
  padding-top: 40px;
}
.footer-bootom-con {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.links-container {
  display: flex;
  gap: 2rem; 
}
.footer-bottom .links {
  text-decoration: none;
  color: inherit; 
  padding: 0px !important;
  margin: 0px !important;
}
p {
  margin: 0; 
  color: $footerlink;
}
@media (max-width: 700px) {
  .footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem; 
    word-wrap: break-word; 
  }

  .links-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; 
  }
}
@media (max-width: 606px) {
  .footer-bootom-con{
    display: none;
  }
  .footer-bootom-con2{
    display: block;
  }
}
@media (min-width: 607px) {
  .footer-bootom-con2{
    display: none;
  }
}
.footer-bottom-con2 {
  display: flex;
  justify-content: left; 
  align-items: center; 
  flex-wrap: wrap; 
  gap: 1rem;  
}
.links-container2 {
  display: flex;
  justify-content: left;  
  align-items: center;
  flex-wrap: wrap;  
  gap: clamp(1rem, 3.35vw + 1rem, 4rem);;
  line-height: 0px;
}
.links-container2 .links {
  text-decoration: none;  
  color: inherit; 
  white-space: normal;  
  overflow-wrap: break-word;  
  word-break: break-word; 
  margin: 0px;
  padding: 0px;
  font-size: 12px !important;
}
.footer-bottom-con2 p {
  margin: 0px; 
  white-space: normal;  
  overflow-wrap: break-word;  
  word-break: break-word;  
  padding: 0px;
  font-size: 12px !important;
}
@media (max-width: 767px){
  .fbbt{
    height: 45px;
    align-items: center;
  }
  .fbbt{
    align-items: center;
  }
  .fbbt .social-links-fs{
    align-items: center;
  }
  .fbbt{
    align-items: top;
  }
  .fbbt .social-links-fs{
    align-items: top;
  }
  .fbbt p{  
    padding-top: 2.5px;
  } 
  .fbbt p{  
    font-size: 14px;
    font-weight: 400;
  }
}
@media (max-width: 1026px){

  #footer .footer-top {
    padding: 0px 0 20px 0;
  }
  .footer-links{
    padding-top: 10px;
  }
  .fbbt .social-links-fs a{
    font-size: 20px;
    width: 30px;
    height: 30px;
    margin-left: 4px;
  }
  .fbbt p{  
    font-size: 14px;
    font-weight: 400;
    padding-top: 72px;
  }
  .btp{
    width: 140px;
    height: 36px;
    font-size: 30px;
  }
  .btp p{
    font-size: 13px;
  }
  .fbt{
    align-items: center;
  }
  #footer .footer-links .footer-contact p{
    font-size: 14px;
    font-weight: 700;
    margin-top: 29px;
    margin-bottom: 10px;
  }
  #footer .footer-links .footer-contact .links{
    font-size: 13px;
  }
}
@media (max-width: 767px){
  .fbt{
    height: 36px;
    display: grid;
    align-items: top;
    justify-content: right;
    background: $footerbg;
  }
  .fbbt{
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: first baseline baseline;
    background: $footerbg;
  }
}
@media (max-width: 570px){
  #footer .footer-top {
    padding: 0px 0 0px 0;
  }
}
@media (max-width: 767px){
  .fbbt p{  
    padding-top: 0px;
  }
}
@media (max-width: 1259px){
  .btp-hide{
    display: none;
  }
}
#video-whole{
  height: auto;
}
#video-intro {
    position: relative;
    padding: 60px 0 40px 0;
    background-color: rgba(0, 0, 0, 0.3);
    height: 80vh;
    min-height: 800px;
}
#video-intro h1{
    font-weight: 400;
    text-align: left;
    font-size: $hfont;
    color: #fff;
}
#video-intro p{
  font-size: $hp;
  color: #fff;
  text-align: left;
  padding-bottom: 30px;
  margin-top: 30px;
}
.video-text {
  position: absolute; 
  bottom: 0;        
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}
@media (max-width: 700px) {
  #video-intro {
      height: 40vh;
      min-height: 360px;
  }
  .video-text{
    display: none;
  }
  #video-whole{
    box-shadow: 0 .125rem .25rem 0 rgba(0,0,0,.13),0 0 .0625rem 0 rgba(0,0,0,.11) !important;
    margin-bottom: 10px;
  }
}
@media (max-width: 500px) {
  #video-intro {
      height: 40vh;
      min-height: 0px;
  }
}
.video-sec-text{
  padding-bottom: 37px;
}
.video-sec-text h1{
  font-weight: 400;
  text-align: left;
  font-size: $hfont;
  color: $background;
  margin-top: 18px;
  --gradient-position: 30%;
  background: -webkit-linear-gradient(348deg, #000000 0%, #000000 20%, #FC66BD 24%, #FFC5E3 25%, #000000 30%, #000000 40%, #28E5FF 42.5%, #0064DA 43%, #000000 45.5%, #000000 60%, #FF31A1 65%, #000000 70%);
  background-position-x: 10%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-size: auto;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  position: relative;  
}
.video-sec-text p{
  font-size: $hp;
  color: $background;
  text-align: left;
  margin: 0px;
  padding-bottom: 30px;
  margin-top: 30px;
}
.arrow-prev,
.arrow-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: #ff99cc;
  animation: shine 3s infinite;
  z-index: 999;
  font-size: 48px;
}
.arrow-prev:active,
.arrow-next:active,
.arrow-prev:focus,
.arrow-next:focus {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: #ff99cc;
  animation: shine 3s infinite;
  z-index: 999; 
  font-size: 48px;
}
.arrow-prev {
  left: 20px;
}

.arrow-next {
  right: 20px;
}
@media (max-width: 767px) {
  .arrow-prev {
    left: 6px;
  }
  
  .arrow-next {
    right: 6px;
  }

  .arrow-prev,
  .arrow-next {
    font-size: 38px;
  }
  .arrow-prev:active,
  .arrow-next:active,
  .arrow-prev:focus,
  .arrow-next:focus {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    color: white;
    z-index: 999; 
    font-size: 38px;
  }
}
@media (min-width: 701px) {
  .video-sec-text{
    display: none;
  }
}
@media (max-width: 701px) {
  .arrow-prev,
  .arrow-next {
    top: 39.5vh;
    font-weight: bolder;
  }
  .arrow-prev:active,
  .arrow-next:active,
  .arrow-prev:focus,
  .arrow-next:focus {
    top: 39.5vh;
    font-weight: bolder;
  }
}

#video-intro:before {
content: "";
}
.video-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #262b3f !important;
    overflow: hidden;
} 
#background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    z-index: -1;
    background-color: #000000 !important;
    overflow: hidden;
}

#help{
  background-color: #FFFFFF;
  height: 451px;
  display: flex;
  align-items: center;
}
#help h1{
  color: #161616;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: clamp(28px, 3.35vw + 1rem, 60px);
  text-align: center;
  padding-bottom: 0px;
  margin-bottom: 9px;
  font-weight: 400;
}
#help p{
  color: #161616;
  font-size: 23px;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  text-align: center;
  margin-top: 0px;
  padding-top: 0px;
}
#help-btn{
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  text-transform: uppercase;
  width: 256px;
  height: 46px !important;
  background-color: #262b3f;
  color: #FFFFFF !important;
  font-size: 13px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 36px;
  transition:  linear !important;
  border: 1px solid #ffffff !important;
  cursor: pointer;
  text-decoration: none;
}
#help-btn:hover{
  background: linear-gradient(264deg, rgba(38,43,63,1) 0%, rgba(0,0,0,1) 100%);
  animation: button-light 0.8s ease-in-out forwards;
}
@media (max-width: 767px) {
  #help p{
    font-size: 14px;
  }
  #help-btn{
    width: 240px;
    height: 43px !important;
    font-size: 12px;
  }
}

#join-us {
  position: relative;
  color: #fff;
  padding: 60px 0 40px 0;
  margin-top: 2px;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 300px;
  min-height: 630px;
}
#join-us h1{
  color: #FFFFFF;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: clamp(28px, 3.35vw + 1rem, 60px);
  text-align: center;
  font-weight: 400;
  margin-top: 40px;
  margin-bottom: 20px;
}
#join-us a{
  color: #FFFFFF !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  height: 50px;
  width: 153px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0 auto;
  border: 1px solid #ffffff;
  transition: 1s;
  margin-top: 6px;
  text-decoration: none;
}
#join-us a:hover{
  background-color: #000000a9;  
  animation: button-light 0.8s ease-in-out forwards;
}
@media (max-width: 767px) {
  #join-us a{
    height: 45px;
    width: 153px;
  }
}

#trans{
  padding: 106px 0px;
}
#trans h1{
  color: $head;
  margin-bottom: 40px;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: clamp(36px, 3.35vw + 1rem, 60px);
  font-weight: 400;
}
@media (min-width: 701px) {
  #trans h1{
    color: #212529;
    margin-bottom: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: clamp(36px, 3.35vw + 1rem, 60px);
    font-weight: 400;
    --gradient-position: 50%;
    background: -webkit-linear-gradient(348deg,#000000 0%,#000000 28.66%,#FC66BD 32.66%,#FFC5E3 33%,#000000 37%,#000000 45%,#28E5FF 47.5%,#0064DA 48%,#000000 50.5%,#000000 64%,#FF31A1 69%,#000000 70%);
      background-position-x: 0%;
      background-position-y: 0%;
      background-repeat: repeat;
      background-attachment: scroll;
      background-size: auto;
      background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    position: relative;  
  }
}
#trans p{
  margin-bottom: 20px;
  color: #262B3F;
  text-align: left;
}
#trans span {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 58px;
  display: block;
  color: #212529;
  font-weight: bold;
}

#trans .cont{
  margin-top: auto;
  margin-bottom: auto;
}
#trans .num{
  text-align: right;
  border-left: 1px solid #dadce0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#trans .num p{
  text-align: right;
}
@media (max-width: 767px) {
  #trans .num p{
    text-align: left;
  }
  #trans .num{
    text-align: left;
    padding-left: 25px;
    border: none;
  }
  #trans span {
    font-size: 40px;
  }
}
@media (max-width: 992px) {
  #trans{
    padding: 60px 0px;
  }
  #trans p{
    margin-bottom: 36px;
  }
  #trans .num{
    text-align: left;
    border-left: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #trans .num p{
    text-align: left;
  }
  #trans h1{
    margin-bottom: 20px;
  } 
}
@media (min-width: 992px) {
  #trans{
    .first{
      margin-bottom: 60px;
    }
  }
}
#company-news{
  padding: 60px 0px 60px 0px;
}
.center{
  display: flex;
  align-items: center;
}
#company-news h1{
  font-size: clamp(28px, 3.35vw + 1rem, 60px);
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  margin-bottom: 28px;
  color: $heading;
}
#company-news .news-link{
  color: $footerlink;
  font-size: 15px;
  text-decoration: none;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;
  padding-bottom: 1px;
  transition: .3s ease-out;
  padding-right: 4px;
}
#company-news .company-news-link-arrow{
  padding-bottom: 1.5px;
  font-size: 18px;
  transition: .3s ease-out;
}
#company-news .news-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 9px;
  height: 1px;
  background-color: currentColor; 
  transition: width 0.3s ease;
}
#company-news .news-link:hover{
  padding-right: 10px;
}
#company-news .news-link:hover::after {
  width: 100%;
}
#company-news .news-card{
  overflow: hidden;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #dadce0;
  border-radius: 8px;
  transition: all .3s;
  height: 400px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-top: 40px;
}
#company-news .news-card img{
  aspect-ratio: 1/0.7;
  width: 100% !important;
}
#company-news .news-card:hover{
  box-shadow: 0 6px 10px 4px rgba(60,64,67,.15),0 2px 3px 0 rgba(60,64,67,.3);
}
#company-news .news-card h2{
  font-weight: 500;
  font-size: 14px;
  color: $headlight;
  margin: 0px;
  padding-left: 15px;
  margin-top: 20px;
  margin-bottom: 30px;
}
#company-news .news-card h1{
  font-weight: normal;
  font-size: 18px;
  color: $heading;
  margin: 0px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 992px) {
  #company-news .card-scroll{
    display: none;
  }
}
@media (max-width: 992px) {
  #company-news h1{
    margin-bottom: 20px;
  }
  #company-news{
    padding: 60px 0px 0px 0px;
  }
  #company-news .card-scroll{
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
  #company-news .news-card{
    margin-left: 50%;
    width: 400px;
    height: auto;
    margin-bottom: 40px;
  }
  #company-news .news-card h1{
    margin-bottom: 50px;
  }
  #company-news .card-hide{
    display: none;
  }
}
@media (max-width: 767px) {
  #company-news .card-scroll{
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }
  #company-news .news-card{
    margin-left: 45%;
    width: 300px;
    height: auto;
    margin-bottom: 40px;
  }
  #company-news .news-card h1{
    margin-bottom: 50px;
  }
  #company-news .card-hide{
    display: none;
  }
}
@media (max-width: 500px) {
  #company-news .card-scroll{
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
  }
  #company-news .news-card{
    margin-left: 49%;
    width: 260px;
    height: auto;
    margin-bottom: 40px;
  }
  #company-news .news-card h1{
    margin-bottom: 50px;
  }
  #company-news .card-hide{
    display: none;
  }
}
.nav-blog{
  height: auto;
  background-color: red;
}



