@import "theme";

.cookies-container {
    width: 100%;
    background-color: $notificationsbg;
    color: $notificationcolour;
    padding: 15px 0px;
    text-align: center;
    transition: transform 0.7s ease-in-out;
    z-index: 9000;
    box-shadow: 0 .125rem .25rem 0 rgba(0,0,0,.13),0 0 .0625rem 0 rgba(0,0,0,.11) !important;
    display: none;
    margin-bottom: 4px;
  }
  .cookies-container .cbtn{
    display: flex;
    align-items: center;
    justify-content: right;
  }
  
  
  .cookies-container.show {
    display: block;
  }
  
  .cookies-title {
    font-size: 18px;
    margin-bottom: 14px;
    color: #d0d0d0;
    font-weight: 500;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    text-align: left;
  }
  
  .cookies-message {
    font-size: 14px ;
    color: $notificationcolour;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    text-align: left;
    margin: 0px;
    padding: 0px;
  }
  
  .cookies-link {
    color: #F26522;
    text-decoration: underline;
    cursor: pointer;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
  }
  
  .cookies-link:hover {
    color: #F26522;
    text-decoration: none;
  }
  .cookies-btn2{
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    background-color: #F26522; 
    color: #fff;
    border: 2px solid #F26522;
    padding: 9px 30px;
    font-size: 16px;
    border-radius: 3px;
    cursor: pointer;
    transition: .1s;
    width: 100%;
    margin: 4px 0px;
  }
  .cookies-btn2:hover{
    cursor: pointer;
    background-color: #fc5c11; 
    color: #fff;
    border: 2px solid #fc5c11;
  }
  .cookies-btn1{
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    background-color: transparent; 
    color: #d0d0d0;
    border: 2px solid #d0d0d0;
    padding: 9px 30px;
    font-size: 16px;
    border-radius: 3px;
    cursor: pointer;
    transition: .1s;
    width: 100%;
    margin: 4px 0px;
  }
  .cookies-btn1:hover{
    cursor: pointer;
    color: #acaaaa;
    border-color: #acaaaa;
  }
  .cookies-btn3 {
    background-color: transparent;
    color: #262b3f;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cookies-btn3:hover {
    background-color: $notofivationhover;
  }
  
  @media (max-width: 992px){
  .cookies-message {
    font-size: 12px;
  }
}

@media (max-width: 500px){
  .cookies-btn3 {
    font-size: 1.4rem;
  }
.cookies-message {
  font-size: 12px;
}
}